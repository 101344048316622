import { lazy, Suspense, useEffect } from "react";
import { Routes, Route, useLocation } from "react-router-dom";
import { setConsentTracking } from "store/ConsentTracking/slice";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "store/reducers";
import { useRollbarPerson } from "@rollbar/react";
import { ModalManager } from "components/Modals/ModalManager";
import { NotificationContainer } from "components/notification/NotificationContainer";
import { useIdentifyTracking } from "hooks/useIdentifyTracking";

const MicrositePage = lazy(() => import("./pages/MicrositePage"));
const EmbedPage = lazy(() => import("./pages/EmbedPage"));
const GatedContentProcessingPage = lazy(
    () => import("./pages/GatedContentProcessingPage")
);
const AuthorizePage = lazy(() => import("./pages/AuthorizePage"));

function App() {
    const location = useLocation();
    const doNotTrack = new URLSearchParams(location.search).get("doNotTrack");
    const dispatch = useDispatch();
    useIdentifyTracking();

    const {
        globalNotifications: { notifications }
    } = useSelector((s: RootState) => s.notifications);

    const creator = useSelector((s: RootState) => s.playerCreator);
    useRollbarPerson({
        id: creator?.details?.userId,
        organizationId: creator?.details?.organizationId,
        stripeAccountId: creator?.details?.stripeAccountId
    });
    useEffect(() => {
        dispatch(setConsentTracking(!!doNotTrack));
    }, [dispatch, doNotTrack]);

    return (
        <>
            <NotificationContainer
                notifications={notifications}
                visible={false}
                fixed={true}
                shouldFade={false}
            />
            <Routes>
                <Route
                    path="/"
                    element={
                        <Suspense fallback={<></>}>
                            <MicrositePage />
                        </Suspense>
                    }
                />
                <Route
                    path="/:projectId"
                    element={
                        <Suspense fallback={<></>}>
                            <MicrositePage />
                        </Suspense>
                    }
                />
                <Route
                    path="/watch"
                    element={
                        <Suspense fallback={<></>}>
                            <MicrositePage />
                        </Suspense>
                    }
                />
                <Route
                    path="/embed"
                    element={
                        <Suspense fallback={<></>}>
                            <EmbedPage />
                        </Suspense>
                    }
                />
                <Route
                    path="/order-processing"
                    element={
                        <Suspense fallback={<></>}>
                            <GatedContentProcessingPage />
                        </Suspense>
                    }
                />
                <Route
                    path="/authorize"
                    element={
                        <Suspense fallback={<></>}>
                            <AuthorizePage />
                        </Suspense>
                    }
                />
            </Routes>
            <ModalManager />
        </>
    );
}

export default App;
