import { createSlice, PayloadAction, Reducer } from "@reduxjs/toolkit";
import type {
    CollectionVideoResponse,
    CollectionVideosResponse,
    DefaultInteractiveTab,
    EntitlementPriceResponse
} from "@switcherstudio/player-api-client";
import {
    ContextMenus,
    type IFrameType,
    type ParentFrame,
    type QualityLevelOptions,
    type VideoSessionState
} from "./types";
import { v4 as uuidv4 } from "uuid";
import type { AdaptationEvent } from "components/ShakaPlayer/types";
import { exchangeCode } from "store/CreatorCustomers/thunks";
import { trackSegmentEvent } from "helpers/analyticsHelpers";

const initialState = {
    upcomingCollectionVideos: undefined,
    currentCollectionVideo: undefined,
    sessionId: uuidv4(),
    video: null,
    playHasStarted: false,
    isPlaying: false,
    isEnded: false,
    isEmbed: false,
    referrerUrl: null,
    parentFrame: null,
    isExpanded: true,
    isVoD: false,
    isFeaturedVideo: false,
    isToolDrawerActive: true,
    activeTool: "About",
    selectedPrice: undefined,
    activeContextMenu: ContextMenus.None,
    liveEventEndedByNextEventAt: null,
    levelLoadError: false,
    userSelectedQualityLevel: "auto",
    constrainedSelectedQualityLevel: "auto",
    isMuted: false,
    userSeeking: false,
    shakaVideoQuality: {
        levelInfo: {
            oldTrack: null,
            newTrack: null
        },
        isAuto: true
    },
    buffering: false,
    isInitialPlay: true,
    timeSliderDelay: null,
    heartbeatDelay: null,
    isFullScreen: false,
    isInitialBuffering: true,
    videoJsAdapter: null,
    seekingStart: null,
    streamingProtocol: undefined,
    isAutoPlay: false,
    hasSetDefault: false,
    preselectedPlaylistBroadcastId: null,
    iframeType: null,
    isIframeVisible: true,
    isMicrosite: false
} as VideoSessionState;

export const videoSession = createSlice({
    name: "videoSession",
    initialState: initialState,
    reducers: {
        // used when switching between players
        clearVideoSession: (state) => {
            // reset state besides some select properties
            return {
                ...initialState,
                referrerUrl: state.referrerUrl,
                parentFrame: state.parentFrame,
                isEmbed: state.isEmbed,
                isExpanded: state.isExpanded,
                iframeType: state.iframeType,
                isMicrosite: state.isMicrosite
            };
        },
        // used when reloading the data after purchase
        refreshVideoSession: (state) => {
            // reset state besides some select properties
            return {
                ...initialState,
                referrerUrl: state.referrerUrl,
                parentFrame: state.parentFrame,
                isEmbed: state.isEmbed,
                isExpanded: state.isExpanded,
                iframeType: state.iframeType,
                sessionId: state.sessionId,
                preselectedPlaylistBroadcastId:
                    state.preselectedPlaylistBroadcastId,
                isAutoPlay: state.isAutoPlay,
                isIframeVisible: state.isIframeVisible,
                isMicrosite: state.isMicrosite
            };
        },
        setUpcomingCollectionVideos: (
            state,
            { payload }: PayloadAction<CollectionVideosResponse>
        ) => {
            state.upcomingCollectionVideos = payload;
        },
        setCurrentCollectionVideo: (
            state,
            {
                payload
            }: PayloadAction<CollectionVideoResponse | undefined | null>
        ) => {
            state.currentCollectionVideo = payload;

            // clear out all the state that is dependent on the current broadcast
            state.isPlaying = false;
            state.isEnded = false;
            state.playHasStarted = false;
            state.liveEventEndedByNextEventAt = null;
            state.levelLoadError = false;
            state.timeSliderDelay = null;
            state.video = null;
        },
        setSessionId: (state, { payload }: PayloadAction<string>) => {
            state.sessionId = payload;
        },
        setVideoSessionVideo: (
            state,
            { payload }: PayloadAction<HTMLVideoElement>
        ) => {
            return {
                ...state,
                video: payload,
                isEnded: false,
                playHasStarted: false,
                levelLoadError: false
            };
        },
        setPlayHasStarted: (state, { payload }: PayloadAction<boolean>) => {
            state.playHasStarted = payload;
        },
        setPlayingState: (state, { payload }: PayloadAction<boolean>) => {
            state.isPlaying = payload;
        },
        setEndedState: (state, { payload }: PayloadAction<boolean>) => {
            state.isEnded = payload;
        },
        setEmbedState: (state, { payload }: PayloadAction<boolean>) => {
            state.isEmbed = payload;
        },
        setReferrerUrl: (state, { payload }: PayloadAction<string | null>) => {
            state.referrerUrl = payload;
        },
        setVoDState: (state, { payload }: PayloadAction<boolean>) => {
            state.isVoD = payload;
        },
        setParentFrame: (state, { payload }: PayloadAction<ParentFrame>) => {
            state.parentFrame = payload;
        },
        setExpandedState: (state, { payload }: PayloadAction<boolean>) => {
            state.isExpanded = payload;
        },
        setToolState: (state, { payload }: PayloadAction<boolean>) => {
            state.isToolDrawerActive = payload;
        },
        setActiveTool: (
            state,
            { payload }: PayloadAction<DefaultInteractiveTab>
        ) => {
            state.activeTool = payload;
        },
        setSelectedPrice: (
            state,
            { payload }: PayloadAction<EntitlementPriceResponse | undefined>
        ) => {
            state.selectedPrice = payload;
        },
        setActiveContextMenu: (
            state,
            { payload }: PayloadAction<ContextMenus>
        ) => {
            state.activeContextMenu = payload;
        },
        setLiveEventEndedByNextEventAt: (
            state,
            { payload }: PayloadAction<number | undefined>
        ) => {
            state.liveEventEndedByNextEventAt = payload;
        },
        setLevelLoadError: (state, { payload }: PayloadAction<boolean>) => {
            state.levelLoadError = payload;
        },
        setUserSelectedQualityLevel: (
            state,
            { payload }: PayloadAction<QualityLevelOptions>
        ) => {
            state.userSelectedQualityLevel = payload;
        },
        setConstrainedSelectedQualityLevel: (
            state,
            { payload }: PayloadAction<QualityLevelOptions>
        ) => {
            state.constrainedSelectedQualityLevel = payload;
        },
        setVolumeMuted: (state, { payload }: PayloadAction<boolean>) => {
            state.isMuted = payload;
        },
        setUserSeeking: (state, { payload }: PayloadAction<boolean>) => {
            state.userSeeking = payload;
        },
        setShakaVideoQuality: (
            state,
            { payload }: PayloadAction<AdaptationEvent>
        ) => {
            state.shakaVideoQuality = {
                ...state.shakaVideoQuality,
                levelInfo: payload
            };
        },
        setShakaVideoQualityIsAuto: (
            state,
            { payload }: PayloadAction<boolean>
        ) => {
            state.shakaVideoQuality = {
                ...state.shakaVideoQuality,
                isAuto: payload
            };
        },
        setBuffering: (state, { payload }: PayloadAction<boolean>) => {
            state.buffering = payload;
        },
        setIsInitialPlay: (state, { payload }: PayloadAction<boolean>) => {
            state.isInitialPlay = payload;
        },
        setTimeSliderDelay: (
            state,
            { payload }: PayloadAction<number | null>
        ) => {
            state.timeSliderDelay = payload;
        },
        setHeartbeatDelay: (
            state,
            { payload }: PayloadAction<number | null>
        ) => {
            state.heartbeatDelay = payload;
        },
        setIsFullScreen: (state, { payload }: PayloadAction<boolean>) => {
            state.isFullScreen = payload;
        },
        setIsInitialBuffering: (state, { payload }: PayloadAction<boolean>) => {
            state.isInitialBuffering = payload;
        },
        setVideoJsAdapter: (state, { payload }: PayloadAction<any>) => {
            state.videoJsAdapter = payload;
        },
        setSeekingStart: (
            state,
            { payload }: PayloadAction<number | null | undefined>
        ) => {
            state.seekingStart = payload;
        },
        setStreamingProtocol: (
            state,
            { payload }: PayloadAction<"dash" | "hls" | undefined>
        ) => {
            state.streamingProtocol = payload;
        },
        setIsAutoPlay: (state, { payload }: PayloadAction<boolean>) => {
            state.isAutoPlay = payload;
        },
        setHasSetDefault: (state, { payload }: PayloadAction<boolean>) => {
            state.hasSetDefault = payload;
        },
        setPreselectedPlaylistBroadcastId: (
            state,
            { payload }: PayloadAction<string | null>
        ) => {
            state.preselectedPlaylistBroadcastId = payload;
        },
        setIframeType: (state, { payload }: PayloadAction<IFrameType>) => {
            state.iframeType = payload;
        },
        setIsIframeVisible: (state, { payload }: PayloadAction<boolean>) => {
            state.isIframeVisible = payload;
        },
        setIsMicrosite: (state, { payload }: PayloadAction<boolean>) => {
            state.isMicrosite = payload;
        }
    },
    extraReducers(builder) {
        builder.addCase(exchangeCode.fulfilled, (state, action) => {
            const creatorStripeAccountId = action.payload.stripeAccountId;
            if (creatorStripeAccountId) {
                state.parentFrame?.distributeAuthorization(action.payload);

                trackSegmentEvent("Creator Customer Authenticated", {
                    creatorCustomerId: action?.payload?.customerId,
                    creatorCustomerEmail: action?.payload?.email
                });
            }
        });
    }
});

export const {
    clearVideoSession,
    refreshVideoSession,
    setUpcomingCollectionVideos,
    setCurrentCollectionVideo,
    setSessionId,
    setVideoSessionVideo,
    setPlayHasStarted,
    setPlayingState,
    setEndedState,
    setVoDState,
    setEmbedState,
    setReferrerUrl,
    setParentFrame,
    setExpandedState,
    setToolState,
    setActiveTool,
    setSelectedPrice,
    setActiveContextMenu,
    setLiveEventEndedByNextEventAt,
    setLevelLoadError,
    setUserSelectedQualityLevel,
    setConstrainedSelectedQualityLevel,
    setVolumeMuted,
    setUserSeeking,
    setShakaVideoQuality,
    setShakaVideoQualityIsAuto,
    setBuffering,
    setIsInitialPlay,
    setTimeSliderDelay,
    setHeartbeatDelay,
    setIsFullScreen,
    setIsInitialBuffering,
    setVideoJsAdapter,
    setSeekingStart,
    setStreamingProtocol,
    setIsAutoPlay,
    setHasSetDefault,
    setPreselectedPlaylistBroadcastId,
    setIframeType,
    setIsIframeVisible,
    setIsMicrosite
} = videoSession.actions;

export default videoSession.reducer as Reducer<VideoSessionState>;
