import { GenericModal } from "components/Modals/GenericModal";

export const DMCAModal = () => {
    return (
        <GenericModal
            dismissButton={"Close"}
            title="Copyright Infringement Claims"
        >
            <p>
                The Digital Millennium Copyright Act of 1998 (the “DMCA”)
                provides recourse for copyright owners who believe that material
                appearing on the Internet infringes their rights under U.S.
                copyright law. If you believe in good faith that materials
                available through the Services infringe your copyright, you (or
                your agent) may send to Switcher a written notice by mail or
                email, requesting that Switcher remove such material or block
                access to it. If you believe in good faith that someone has
                wrongly filed a notice of copyright infringement against you,
                the DMCA permits you to send to Switcher a counter-notice.
                Notices and counter-notices must meet the then-current statutory
                requirements imposed by the DMCA. See http://www.copyright.gov/
                for details.
            </p>
            <p>
                Notices and counter-notices must be sent in writing to us as
                follows:
            </p>
            <p>
                <b>By mail to:</b>
                <br />
                <span></span>Switcher Support Team Switcher, Inc. 3058 Bardstown
                Rd #1060 Louisville, KY 40205
            </p>
            <p>
                {" "}
                <b>By email to:</b>
                <br />
                <a href="mailto:support@switcherstudio.com">
                    support@switcherstudio.com
                </a>
            </p>
        </GenericModal>
    );
};
